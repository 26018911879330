<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/borrower_benefit_hero.jpg')"
      :mobileImage="require('@/assets/images/borrower_benefit_hero_mobile.jpg')"
    >
      <b-container class="h-100">
        <b-row align-h="start" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="h1 font-weight-bolder mb-4">
                  Get Your Best Credit Score in Days
                </p>
                <h1 class="h4 mb-4">
                  Use ScoreMaster<sup>&reg;</sup> to quickly get your best
                  credit score and then keep it as you spend.
                </h1>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                  >Sign Up Now!</SignupLinkButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="mt-5 pt-5 px-0">
      <div class="alternating-blocks fullwidth">
        <div class="block testimonials justify-content-center">
          <p class="h2 font-weight-bolder mb-4 d-block d-md-none mobile-title">
            Real People, Real Results
          </p>
          <div class="block-testimonial px-4 mt-4 mt-sm-0">
            <div class="d-flex">
              <div>
                <img
                  src="@/assets/images/testimonial_4.png"
                  height="85"
                  alt="JB testimonial"
                />
              </div>
              <div class="ml-4 text-left" style="font-size: .9em;">
                <p class="text-primary mb-1">
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <small
                    class="ml-3 align-center"
                    style="height: 15px; line-height: 15px;"
                    >Verified Purchase</small
                  >
                </p>
                <p>
                  The site is great! I love that I can see my new report when
                  ever I need.
                </p>
                <p class="text-gray-400">- A.A. in Tustin, CA</p>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <img
                  src="@/assets/images/testimonial_5.png"
                  height="85"
                  alt="JB testimonial"
                />
              </div>
              <div class="ml-4 text-left pr-xl-5" style="font-size: .9em;">
                <p class="text-primary mb-1">
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <small
                    class="ml-3 align-center"
                    style="height: 15px; line-height: 15px;"
                    >Verified Purchase</small
                  >
                </p>
                <p>
                  These guys actually did what they said they could do. Very
                  impressed &amp; worth every penny.
                </p>
                <p class="text-gray-400">- K.S. in Phoenix, AZ</p>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <img
                  src="@/assets/images/testimonial_6.png"
                  height="85"
                  alt="JB testimonial"
                />
              </div>
              <div class="ml-4 text-left" style="font-size: .9em;">
                <p class="text-primary mb-1">
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <IconStar class="icon icon-primary" />
                  <small
                    class="ml-3 align-center"
                    style="height: 15px; line-height: 15px;"
                    >Verified Purchase</small
                  >
                </p>
                <p>
                  This is great for all of you looking to buy a home with the
                  highest credit score possible.
                </p>
                <p class="text-gray-400">- J.B. in Seattle, WA</p>
              </div>
            </div>
          </div>
          <div class="block-content">
            <p class="h2 font-weight-bolder mb-4 d-none d-md-block">
              Real People, Real Results
            </p>
            <p class="h5 font-weight-bold">
              The average is +54 points in <br class="d-md-none" />20 days
            </p>
            <p>
              A better credit score gives you easier access to loans, more
              borrowing power and better rates.
            </p>
            <p>
              Before you apply for a loan, financing, appliance or even an
              apartment, use ScoreMaster<sup>&reg;</sup> first to get a better
              deal &amp; save money.
            </p>
            <SignupLinkButton
              :to="{ name: 'IndividualSignup' }"
              pill
              variant="secondary"
              class="w-100 mt-4"
            >
              Get ScoreMaster<sup>&reg;</sup>
            </SignupLinkButton>
          </div>
        </div>
        <AlternatingBlock
          :image="require('@/assets/images/borrower_benefit_uniqueplan.jpg')"
          :mobileImage="
            require('@/assets/images/borrower_benefit_uniqueplan_mobile.jpg')
          "
          title="credit score monitoring tool for borrower"
          titleMobile="credit score monitoring in mobile for borrower"
        >
          <template slot="title">
            Your Unique Plan
          </template>
          <p class="h5 font-weight-bold">
            A Plan for You, Not The Averages
          </p>
          <p>
            ScoreMaster<sup>&reg;</sup> lets you save a precise Plan that is
            unique for your credit situation showing you how much to pay and to
            which account. Your Post Payment By dates are not your due dates but
            are uniquely timed by ScoreMaster<sup>&reg;</sup> to be prior your
            creditor’s reporting cycle.
          </p>
          <p class="h5 font-weight-bold">
            Simple, Easy &amp; Fun
          </p>
          <p>
            Slide the dial with one finger to easily see your available credit
            score points and how to get them. You can also see how to avoid
            lowering your credit score as you spend.
          </p>
          <b-button
            to="/how-it-works/"
            pill
            size="lg"
            variant="secondary"
            class="w-100 text-center"
          >
            See How It Works
          </b-button>
        </AlternatingBlock>
        <AlternatingBlock
          :image="require('@/assets/images/borrower_benefit_scoreandmore.jpg')"
          :mobileImage="
            require('@/assets/images/borrower_benefit_scoreandmore_mobile.jpg')
          "
          alignment="right"
          title="money manager and credit score tool for borrower"
          titleMobile="money manager and credit monitoring for borrower"
        >
          <template slot="title">
            A Full Suite of Features
          </template>
          <p class="h5 font-weight-bold">
            Your Money, Credit &amp; Identity in One Place
          </p>
          <p>
            Your membership includes ScoreMaster<sup>&reg;</sup>,
            ScoreBuilder<sup>&reg;</sup>, ScoreTracker, Money Manager, Smart
            Credit Report<sup>&reg;</sup>, 3B Report &amp; Scores and $1 Million
            in Fraud Protection for your whole family and more!
          </p>
          <p>
            Use the Action buttons to ask questions or solve problems on your
            credit report or online banking transactions.
          </p>
          <b-button
            to="/features/"
            pill
            size="lg"
            variant="secondary"
            class="w-100 text-center"
          >
            See All Features
          </b-button>
        </AlternatingBlock>
      </div>
    </b-container>
    <p id="disclaimer" class="text-left">
      <small class="d-block mt-2">
        <small class="font-weight-semibold"
          >The testimonial reviews are real reviews from verified purchases.
          However, all photography is for illustrative purposes only.</small
        ></small
      >
    </p>
  </div>
</template>

<script>
import AlternatingBlock from '@/components/common/AlternatingBlock'
import IconStar from '@/assets/svg/icon_star.svg'

export default {
  name: 'IndividualBenefits',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    AlternatingBlock,
    IconStar,
  },
  data() {
    return {
      fields: [
        {
          key: 'Member',
        },
        {
          key: 'Initial Credit Score',
        },
        {
          key: '# of Points Achieved',
          label: 'Points Achieved*',
          variant: 'info',
        },
        {
          key: 'Credit Score Achieved',
          label: 'Credit Score Achieved*',
          class: 'd-none d-md-table-cell',
        },
        {
          key: '# of Days to Achieve',
          label: 'Days to Achieve*',
          variant: 'info',
        },
      ],
      arrowOpacity: 1,
      schemaData: [
        {
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: 'ScoreMaster® Consumer Credit Report Benefits',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '16795 Von Karman # 230',
            addressLocality: 'Irvine',
            addressRegion: 'California',
            postalCode: '92606',
            addressCountry: 'United States',
          },
          telephone: '+18772100180',
          openingHours:
            'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
          geo: {
            '@type': 'GeoCoordinates',
            name: 'ScoreMaster',
            postalCode: '92606',
            latitude: '33.6960883',
            longitude: '-117.8397577',
            description: 'You can find our office in this location.',
          },
          url: 'https://www.scoremaster.com/',
          logo:
            'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
          image:
            'https://www.scoremaster.com/img/borrower_benefit_uniqueplan.418d0624.jpg',
          priceRange: '$$',
          aggregateRating: {
            ratingValue: '5',
            ratingCount: '65',
          },
        },
        {
          '@context': 'https://schema.org',
          '@type': ['Service'],
          serviceType: 'Individual Benefits Services',
          '@id': 'https://www.scoremaster.com/individual-benefits/#service',
          name: 'Individual Benefits',
          additionalType: '',
          sameAs: ':productontology.org/id/',
          brand: '',
          description:
            'A better credit score gives you easier access to loans, more borrowing power and better rates. Before you apply for a loan, financing, appliance or even an apartment, use ScoreMaster® first to get a better deal & save money.',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.scoremaster.com/individual-benefits/#image',
            url:
              'https://www.scoremaster.com/img/borrower_benefit_uniqueplan.418d0624.jpg',
            caption: 'Individual Benefits Services',
          },
          potentialAction: 'Get ScoreMaster',
          provider: {
            '@id': 'https://www.google.com/maps?cid=11670868934081200217',
          },
          serviceOutput:
            'ScoreMaster to improve Credit Score for a better Mortgage & Loan',
          url: 'https://www.scoremaster.com/individual-benefits/',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          isPartOf: 'https://www.scoremaster.com/#website',
          '@id': 'https://www.scoremaster.com/individual-benefits/#faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'What is a good personal credit score?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'A good personal credit score is between 670 to 739. However, this range can vary and it depends on the credit scoring model. Most of the times, credit scores from 580 to 669 are usually considered fair, 670 to 739 as good and 740 to 799 as good while 800 and up are considered excellent. ',
              },
            },
            {
              '@type': 'Question',
              name: 'Can you calculate your own credit score?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'It is impossible to calculate a credit score yourself. However, you can monitor your score for free and the usual factors that promote food scores are also well known and worth understanding. ',
              },
            },
            {
              '@type': 'Question',
              name: 'What credit score does everyone start with?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'In the United States, most people start off with a credit score of 300 and sometimes low. It usually depends on the scoring system. Not to mention that you cannot have a creit score of zero. ',
              },
            },
          ],
        },
      ],
    }
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
}
</script>

<style lang="scss">
@media (min-width: 768px) {
  .alternating-blocks.fullwidth .testimonials {
    max-width: 1200px;
    margin: 0 auto 6rem;

    .block-testimonial {
      order: 2;
      flex: 1;
    }
    .block-content {
      flex: 1;
    }
  }
}
@media (min-width: 991px) {
}
</style>
