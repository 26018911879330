<template>
  <div>
    <p v-if="submitError" class="form-group--error text-center mb-1 mt-5 mx-5">
      {{ submitError }}
    </p>
    <Loader v-if="loading" loadingText="Submitting" />
    <b-form
      @submit.prevent="executeRecaptcha"
      novalidate
      class="borrowers-form"
      :class="{ loading: loading }"
    >
      <b-row align-h="between">
        <b-col cols="12" lg="6">
          <b-form-group
            id="firstname-group"
            label="Your First Name*"
            label-for="firstname"
            label-size="sm"
          >
            <b-form-input
              id="firstname"
              v-model.trim.lazy="$v.form.firstname.$model"
              required
              :class="{
                'form-group--error': $v.form.firstname.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.firstname)
            }}</small>
          </b-form-group>
          <b-form-group
            id="lastname-group"
            label="Your Last Name*"
            label-for="lastname"
            label-size="sm"
          >
            <b-form-input
              id="lastname"
              v-model.trim.lazy="$v.form.lastname.$model"
              required
              :class="{
                'form-group--error': $v.form.lastname.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.lastname)
            }}</small>
          </b-form-group>
          <b-form-group
            id="email-group"
            label="Your Email Address*"
            label-for="email"
            label-size="sm"
          >
            <b-form-input
              id="email"
              type="email"
              v-model.trim.lazy="$v.form.email.$model"
              required
              :class="{
                'form-group--error': $v.form.email.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.email)
            }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            id="friend-group"
            label="Friend Referral Email*"
            label-for="friend"
            label-size="sm"
          >
            <b-form-input
              id="friend"
              type="email"
              required
              v-model.trim.lazy="$v.form.friend.$model"
              :class="{
                'form-group--error': $v.form.friend.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.friend)
            }}</small>
          </b-form-group>
        </b-col>
      </b-row>

      <Recaptcha ref="recaptcha" @gVerify="submitForm" />

      <b-button
        :disabled="$v.form.$invalid || loading"
        pill
        size="lg"
        type="submit"
        variant="secondary"
        class="w-100 mt-4 mb-3 mx-auto d-block"
        >Continue Enrollment</b-button
      >
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  required,
  email,
  maxLength,
  sameAs,
  not,
} from 'vuelidate/lib/validators'
import { validationMessage } from 'vuelidate-messages'
import Recaptcha from '@/components/forms/Recaptcha'

const formMessages = {
  required: () => 'This field is required',
  email: () => 'Please enter a valid email',
  maxLength: () => 'Please enter a valid email',
  notSame: () => 'Please enter a valid Friend email',
}

export default {
  name: 'BorrowerSignup',
  components: {
    Recaptcha,
  },
  data() {
    return {
      loading: false,
      gSiteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
      submitError: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        friend: '',
      },
    }
  },
  validations: {
    form: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        required,
        email,
        maxLength: maxLength(254),
      },
      friend: {
        required,
        email,
        notSame: not(sameAs('email')),
        maxLength: maxLength(254),
      },
    },
  },
  computed: {
    ...mapGetters({
      memberLink: 'member/getMemberLink',
      advocacyPID: 'member/getAdvocacyPID',
    }),
  },
  methods: {
    submitForm() {
      if (!this.$v.form.$invalid) {
        let calls = []

        // Borrower subscriber
        calls.push(
          btoa(
            JSON.stringify({
              token: 'NfkyyZ',
              properties: {
                $email: this.form.email,
                $first_name: this.form.firstname,
                $last_name: this.form.lastname,
                ProfileType: 'Borrower',
              },
            })
          )
        )

        const referrer = {
          ReferrerFirstName: this.form.firstname,
          ReferrerLastName: this.form.lastname,
          ReferrerEmail: this.form.email,
        }

        // Friend referral
        if (this.form.friend) {
          const friend = Object.assign(
            {
              $email: this.form.friend,
              ProfileType: 'Friend',
            },
            referrer
          )

          calls.push(
            btoa(
              JSON.stringify({
                token: 'NfkyyZ',
                properties: friend,
              })
            )
          )
        }

        // Send all queued up api calls to method
        this.sendKlaviyoData(calls)
          .then(() => {
            this.loading = false
            // Klaviyo API to add subscriber
            window._learnq = window._learnq || []
            // Push borrower info
            window._learnq.push([
              'identify',
              {
                $email: this.form.email,
                $first_name: this.form.firstname,
                $last_name: this.form.lastname,
                ProfileType: 'Borrower',
              },
            ])

            if (this.$gtm.enabled()) {
              this.$gtm.trackView(
                'ConsumerSignupAdvocacy',
                '/consumer-signup/advocacy'
              )
              this.$gtm.trackEvent('consumer_signup')
            }

            let args = `pid=${this.advocacyPID}`
            if (localStorage.getItem('aid'))
              args += `&aid=${localStorage.getItem('aid')}`
            if (localStorage.getItem('adid'))
              args += `&adid=${localStorage.getItem('adid')}`
            if (localStorage.getItem('tid'))
              args += `&tid=${localStorage.getItem('tid')}`
            if (localStorage.getItem('sid'))
              args += `&sid=${localStorage.getItem('sid')}`
            if (localStorage.getItem('cid'))
              args += `&cid=${localStorage.getItem('cid')}`

            window.location = `${this.memberLink}join/?${args}&firstName=${this.form.firstname}&lastName=${this.form.lastname}&email=${this.form.email}`
          })
          .catch(() => {
            this.loading = false
            this.submitError =
              'Sorry we had a problem with the form. Please try again or contact support for assistance.'
          })
      }
    },

    sendKlaviyoData(data) {
      return Promise.all(
        data.map(x => axios.get(`https://a.klaviyo.com/api/identify?data=${x}`))
      )
    },
    // display error message
    validationMsg: validationMessage(formMessages),

    // execute the recaptcha widget
    executeRecaptcha() {
      this.loading = true
      this.$refs.recaptcha.execute()
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  position: relative;
}
.loading {
  opacity: 0.4;
}
.loader {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
