var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.submitError)?_c('p',{staticClass:"form-group--error text-center mb-1 mt-5 mx-5"},[_vm._v(" "+_vm._s(_vm.submitError)+" ")]):_vm._e(),(_vm.loading)?_c('Loader',{attrs:{"loadingText":"Submitting"}}):_vm._e(),_c('b-form',{staticClass:"borrowers-form",class:{ loading: _vm.loading },attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.executeRecaptcha.apply(null, arguments)}}},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"firstname-group","label":"Your First Name*","label-for":"firstname","label-size":"sm"}},[_c('b-form-input',{class:{
              'form-group--error': _vm.$v.form.firstname.$error,
            },attrs:{"id":"firstname","required":""},model:{value:(_vm.$v.form.firstname.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.firstname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.firstname.$model"}}),_c('small',{staticClass:"mt-2 form-group--error"},[_vm._v(_vm._s(_vm.validationMsg(_vm.$v.form.firstname)))])],1),_c('b-form-group',{attrs:{"id":"lastname-group","label":"Your Last Name*","label-for":"lastname","label-size":"sm"}},[_c('b-form-input',{class:{
              'form-group--error': _vm.$v.form.lastname.$error,
            },attrs:{"id":"lastname","required":""},model:{value:(_vm.$v.form.lastname.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.lastname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.lastname.$model"}}),_c('small',{staticClass:"mt-2 form-group--error"},[_vm._v(_vm._s(_vm.validationMsg(_vm.$v.form.lastname)))])],1),_c('b-form-group',{attrs:{"id":"email-group","label":"Your Email Address*","label-for":"email","label-size":"sm"}},[_c('b-form-input',{class:{
              'form-group--error': _vm.$v.form.email.$error,
            },attrs:{"id":"email","type":"email","required":""},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.email.$model"}}),_c('small',{staticClass:"mt-2 form-group--error"},[_vm._v(_vm._s(_vm.validationMsg(_vm.$v.form.email)))])],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"friend-group","label":"Friend Referral Email*","label-for":"friend","label-size":"sm"}},[_c('b-form-input',{class:{
              'form-group--error': _vm.$v.form.friend.$error,
            },attrs:{"id":"friend","type":"email","required":""},model:{value:(_vm.$v.form.friend.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.friend, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.friend.$model"}}),_c('small',{staticClass:"mt-2 form-group--error"},[_vm._v(_vm._s(_vm.validationMsg(_vm.$v.form.friend)))])],1)],1)],1),_c('Recaptcha',{ref:"recaptcha",on:{"gVerify":_vm.submitForm}}),_c('b-button',{staticClass:"w-100 mt-4 mb-3 mx-auto d-block",attrs:{"disabled":_vm.$v.form.$invalid || _vm.loading,"pill":"","size":"lg","type":"submit","variant":"secondary"}},[_vm._v("Continue Enrollment")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }