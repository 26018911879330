<template>
  <div>
    <div id="hubspotForm" v-once></div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeBenefitsHubSpotForm',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          target: '#hubspotForm',
          portalId: '9409493',
          formId: '4e6473c8-3756-47ac-b0a2-8d94fe4eefc5',
        })
      }
    })
  },
}
</script>

<style lang="scss" scoped></style>
