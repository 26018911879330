<template>
  <div>
    <div class="legend text-center">
      <p class="font-weight-bold text-center">
        <template v-if="showBeforeChart">
          The ScoreMaster<sup>&reg;</sup> Benefit <br class="d-xl-none" />Before
          the Loan Closes*
        </template>
        <template v-else>
          Benefits of ScoreMaster<sup>&reg;</sup> <br class="d-xl-none" />When
          Selling the Loan*
        </template>
      </p>
      <span class="with-scoremaster">with ScoreMaster<sup>&reg;</sup></span>
      <span class="without-scoremaster"
        >without ScoreMaster<sup>&reg;</sup></span
      >
    </div>
    <div v-if="showBeforeChart">
      <line-chart
        :width="600"
        :height="300"
        :chart-data="data"
        :options="beforeData.options"
      ></line-chart>
    </div>
    <div v-else>
      <line-chart
        :width="600"
        :height="300"
        :chart-data="data"
        :options="afterData.options"
      ></line-chart>
    </div>
    <b-button-group class="w-100 mb-3">
      <b-button
        pill
        size="lg"
        variant="info"
        class="font-weight-bold mt-4 text-info"
        :class="{ showing: showBeforeChart }"
        data-chart="before"
        @click="changeCharts(true)"
      >
        Before Loan <span class="d-none d-md-inline">Closing</span>
      </b-button>
      <b-button
        pill
        size="lg"
        variant="info"
        class="font-weight-bold mt-4 text-info"
        :class="{ showing: !showBeforeChart }"
        data-chart="after"
        @click="changeCharts(false)"
      >
        Selling the Loan
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import LineChart from './LineChart'
import { beforeLoanData, afterLoanData } from '@/assets/data/CreditScoreData'

export default {
  name: 'LenderChart',
  components: {
    LineChart,
  },
  data() {
    return {
      data: {
        datasets: [
          {
            label: 'With ScoreMaster',
            backgroundColor: 'transparent',
            borderColor: '#63CDF6',
            borderWidth: 5,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            data: beforeLoanData.with,
          },
          {
            label: 'Without ScoreMaster',
            backgroundColor: 'transparent',
            borderColor: '#1f4dc5',
            borderDash: [3, 4],
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            data: beforeLoanData.without,
          },
        ],
      },
      showBeforeChart: true,
      beforeData: {
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 2,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: 'Credit Score',
                },
                ticks: {
                  suggestedMin: 580,
                  suggestedMax: 800,
                },
              },
            ],
            xAxes: [
              {
                type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: 'Days',
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 30,
                },
              },
            ],
          },
        },
      },
      afterData: {
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 2,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: 'Credit Score',
                },
                ticks: {
                  suggestedMin: 580,
                  suggestedMax: 800,
                },
              },
            ],
            xAxes: [
              {
                type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: 'Months',
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 6,
                },
              },
            ],
          },
        },
      },
    }
  },
  methods: {
    changeCharts: function(beforeChart) {
      if (beforeChart) {
        this.data = {
          datasets: [
            {
              label: 'With ScoreMaster',
              backgroundColor: 'transparent',
              borderColor: '#63CDF6',
              borderWidth: 5,
              pointBorderColor: 'transparent',
              pointBackgroundColor: 'transparent',
              data: beforeLoanData.with,
            },
            {
              label: 'Without ScoreMaster',
              backgroundColor: 'transparent',
              borderColor: '#1f4dc5',
              borderDash: [3, 4],
              pointBorderColor: 'transparent',
              pointBackgroundColor: 'transparent',
              data: beforeLoanData.without,
            },
          ],
        }
      } else {
        this.data = {
          datasets: [
            {
              label: 'With ScoreMaster',
              backgroundColor: 'transparent',
              borderColor: '#63CDF6',
              borderWidth: 5,
              pointBorderColor: 'transparent',
              pointBackgroundColor: 'transparent',
              data: afterLoanData.with,
            },
            {
              label: 'Without ScoreMaster',
              backgroundColor: 'transparent',
              borderColor: '#1f4dc5',
              borderDash: [3, 4],
              pointBorderColor: 'transparent',
              pointBackgroundColor: 'transparent',
              data: afterLoanData.without,
            },
          ],
        }
      }
      this.showBeforeChart = beforeChart
      this.$emit('switch', beforeChart)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.btn {
  &:first-child {
    margin-right: 0.5rem;
  }
  &:not(.showing) {
    background: none;
    border-width: 3px;
  }

  &.showing {
    color: $white !important;
  }
}
.with-scoremaster,
.without-scoremaster {
  font-size: 14px;
  margin: 0 8px;

  &:before {
    content: '';
    height: 4px;
    width: 25px;
    background: $info;
    display: inline-block;
    margin: 0 5px 2px 0;
    border-radius: 6px;
  }
}
.without-scoremaster {
  &:before {
    background-color: transparent;
    background-image: linear-gradient(
      90deg,
      $primary,
      $primary 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 7px 1px;
  }
}

@media (min-width: 768px) {
  .btn {
    margin: 0 1rem;
  }
}
</style>
