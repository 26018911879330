<template>
  <div
    id="g-recaptcha"
    data-size="invisible"
    class="g-recaptcha"
    :data-sitekey="gSiteKey"
  ></div>
</template>

<script>
export default {
  name: 'Recaptcha',
  data() {
    return {
      gSiteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
      widgetId: 0,
    }
  },
  mounted() {
    window.recaptchaLoaded = this.render

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit'
    )
    recaptchaScript.async = true
    recaptchaScript.defer = true
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.widgetId)
    },
    reset() {
      window.grecaptcha.reset(this.widgetId)
    },
    render() {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.gSiteKey,
          size: 'invisible',
          // the callback executed when the user solve the recaptcha
          callback: response => {
            // emit an event called gVerify with the response as payload
            this.$emit('gVerify', response)
            // reset the recaptcha widget so you can execute it again
            this.reset()
          },
        })
      }
    },
  },
}
</script>
