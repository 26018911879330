<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/borrower_signup_hero.jpg')"
      :mobileImage="require('@/assets/images/borrower_signup_hero_mobile.jpg')"
      :height="'450px'"
      :mobileHeight="'450px'"
    >
      <b-container class="h-100">
        <b-row align-h="start" class="h-100">
          <b-col cols="12" lg="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="h1 font-weight-bolder mb-4">
                  Two Pricing Options
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="py-3">
      <b-row class="my-5">
        <b-col cols="12" lg="4" offset-lg="1">
          <div class="bg-primary-lighten text-white color-title-block">
            <p class="h3 font-weight-bolder">Advocate Pricing</p>
            <p class="h5">
              Refer someone and save money
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="5">
          <p class="h5 mt-5 mb-3 font-weight-semibold">
            14 days free, then 50% off thereafter!
          </p>
          <p>
            This limited time offer includes all of our great features and you
            may cancel at any time. After 14 days free, you only pay $9.95 per
            month &amp; $14.95 per 3B Report &amp; Score you order.
          </p>
          <p>
            <strong
              >To get this advocate pricing you'll need to enter a friend
              referral email.</strong
            >
          </p>
          <BorrowersForm />
        </b-col>
      </b-row>
      <b-row class="mb-3 pt-3">
        <b-col cols="12" lg="4" offset-lg="1">
          <div class="bg-gray-500 text-white color-title-block">
            <p class="h3 font-weight-bolder">Regular Pricing</p>
            <p class="h5 d-none d-lg-block">
              Get started with all the features.
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="5">
          <h1 class="h5 mt-5 mb-3 font-weight-semibold">
            Get started with ScoreMaster<sup>&reg;</sup> and see your points!
          </h1>
          <p class="mb-3">
            $19.95 per Month & $29.95 per 3B Report & Score you order. Includes
            all features, you may cancel any time.
          </p>
          <b-button
            @click="dispatchPageView"
            pill
            size="lg"
            :href="signupLink"
            variant="secondary"
            class="w-100 mt-5 mb-5 mx-auto d-block"
            >Get Started Now!</b-button
          >
        </b-col>
      </b-row>
      <p class="h1 mb-5 pt-5 font-weight-bolder text-center">
        Included with Your Membership
      </p>
      <b-row align-h="center" class="feature-grid">
        <b-media class="mb-4 col-xl-3 col-12 col-lg-4">
          <template v-slot:aside>
            <IconSM style="width:49px;" class="icon icon-primary" />
          </template>
          <p class="font-weight-semibold">ScoreMaster<sup>&reg;</sup></p>
          <p>
            Add more points to your credit score and see how long it will take.
          </p>
        </b-media>
        <b-media class="mb-4 col-xl-3 col-12 offset-xl-1 col-lg-4">
          <template v-slot:aside>
            <IconSB style="width:49px;" class="icon icon-primary" />
          </template>
          <p class="font-weight-semibold">ScoreBuilder<sup>&reg;</sup></p>
          <p>
            See what's hurting your credit score. Use our Action buttons to ask
            questions and resolve problems directly with the source.
          </p>
        </b-media>
        <b-media class="mb-4 col-xl-3 col-12 offset-xl-1 col-lg-4">
          <template v-slot:aside>
            <IconST style="width:49px;" class="icon icon-primary" />
          </template>
          <p class="font-weight-semibold">ScoreTracker</p>
          <p>
            Easily track all your scores with simple charts and in-depth
            information.
          </p>
        </b-media>
      </b-row>
      <b-row align-h="center" class="feature-grid">
        <b-media class="mb-4 col-xl-3 col-12 col-lg-4">
          <template v-slot:aside>
            <IconSCR style="width:49px;" class="icon icon-primary" />
          </template>
          <p class="font-weight-semibold">
            Smart Credit Report<sup>&reg;</sup>
          </p>
          <p>
            A simple and innovative way to view your credit report. Use our
            Action buttons to quickly ask questions and resolve problems.
          </p>
        </b-media>
        <b-media class="mb-4 col-xl-3 col-12 offset-xl-1 col-lg-4">
          <template v-slot:aside>
            <IconMM style="width:49px;" class="icon icon-primary" />
          </template>
          <p class="font-weight-semibold">Money Manager</p>
          <p>
            All your online banking in one place. Use our Action buttons to
            quickly ask questions and resolve problems.
          </p>
        </b-media>
        <b-media class="mb-4 col-xl-3 col-12 offset-xl-1 col-lg-4">
          <template v-slot:aside>
            <IconFI style="width:49px;" class="icon icon-primary" />
          </template>
          <p class="font-weight-semibold">$1 Million Fraud Insurance</p>
          <p>
            Covers Bank, Savings, Brokerage, Lines of Credit, Credit Card and
            more for your whole family.
            <br />
            <br />
            <em><small>Activation required after enrollment.</small></em>
          </p>
        </b-media>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BorrowersForm from '@/components/forms/BorrowersForm'
import IconSM from '@/assets/svg/icon_SM.svg'
import IconSB from '@/assets/svg/icon_SB.svg'
import IconST from '@/assets/svg/icon_ST.svg'
import IconSCR from '@/assets/svg/icon_SCR.svg'
import IconMM from '@/assets/svg/icon_MM.svg'
import IconFI from '@/assets/svg/icon_FI.svg'

export default {
  name: 'IndividualSignup',
  components: {
    BorrowersForm,
    IconSM,
    IconSB,
    IconST,
    IconSCR,
    IconMM,
    IconFI,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  computed: {
    signupLink: function() {
      return this.$store.getters['member/getSignupLink']
    },
  },
  methods: {
    dispatchPageView() {
      if (this.$gtm.enabled())
        this.$gtm.trackView(
          'ConsumerSignupStandard',
          '/individual-signup/standard'
        )
    },
  },
}
</script>
