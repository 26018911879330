<template>
  <div>
    <div class="block" :class="`image-${alignment}`">
      <p
        class="h2 font-weight-bolder mb-4 mt-3 d-block d-md-none mobile-title"
        v-if="hasTitleSlot"
      >
        <slot name="title" />
      </p>
      <div class="block-image">
        <b-img :src="image" :alt="title" fluid class="d-none d-md-block" />
        <b-img
          :src="mobileImage"
          :alt="titleMobile ? titleMobile : title"
          fluid
          block
          class="d-md-none"
        />
      </div>
      <div class="block-content">
        <p
          class="h2 font-weight-bolder mb-4 d-none d-md-block"
          v-if="hasTitleSlot"
        >
          <slot name="title" />
        </p>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlternatingBlock',
  props: {
    image: String,
    mobileImage: String,
    title: String,
    titleMobile: String,
    alignment: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
  },
}
</script>
