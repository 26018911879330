<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/employee-benefits/brokers_hero.jpg')"
      :mobileImage="
        require('@/assets/images/employee-benefits/brokers_hero_mobile.jpg')
      "
      height="600px"
      mobileHeight="300px"
      class="sm-mb-140px"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6">
            <b-row>
              <b-col class="hero-text p-4 p-lg-5 bg-white box-shadow">
                <h1 class="h1 font-weight-bolder mb-4">
                  The Final Piece to Your Financial Wellness Package
                </h1>
                <p class="h4">
                  Boost morale, improve productivity, and increase retention for
                  employers by improving employees’ financial lives.
                </p>
                <div class="mt-4 d-flex align-items-center">
                  <SignupLinkButton
                    :to="{ name: 'EmployerSignup' }"
                    pill
                    variant="secondary"
                    class="employee-benefits-btn mr-4"
                    >Contact Us</SignupLinkButton
                  >
                  <a @click="openVideo" href="#" class="open-video">
                    See how it works
                    <PlayButton class="ml-1" />
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container fluid class="mt-5 mb-5 pb-4 pt-3 pt-sm-0">
      <b-row>
        <b-col col="12" class="mt-4 mb-4">
          <div class="sm-container">
            <b-row>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span>55%</span>
                  <p>
                    55% of Americans worry about an unplanned financial
                    emergency
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span>40%</span>
                  <p>40% of Americans have no plan for handling an emergency</p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span>60%</span>
                  <p>
                    60% of Americans are unable to cover an unexpected $500
                    expense
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="h1 text-center font-weight-lightbold">
            Yet, only <span class="font-weight-bold">52%</span> of companies
            have financial wellness programs
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-200 pt-lg-5 pt-4">
      <b-row>
        <b-col cols="12" class="text-center mt-4">
          <h2 class="h2 font-weight-bolder mb-4">
            What Your Clients Expect
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col col="12" class="mt-5 mb-4">
          <div class="sm-container">
            <b-row>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <IconLightBulb class="mb-4" />
                  <p class="h5 font-weight-bold mb-4">
                    Industry Knowledge
                  </p>
                  <p>
                    You can identify emerging benefits offerings and create
                    innovative wellness packages
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <IconCoins class="mb-4" />
                  <p class="h5 font-weight-bold mb-4">
                    Budget Consciousness
                  </p>
                  <p>
                    You can design benefits packages that meet their budget and
                    desired results
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <IconShakingHands class="mb-4" />
                  <p class="h5 font-weight-bold mb-4">
                    Customer Care
                  </p>
                  <p>
                    You can foster a strong business relationship by providing
                    lasting value
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5 pt-5 no-gutters">
      <h2 class="h2 font-weight-bolder mb-4 text-center">
        How We Can Help
      </h2>
      <p class="text-center mb-5">
        Searching for options? Working on an RFP? We have the tools to make it
        easy.
      </p>
      <div class="alternating-blocks fullwidth pt-4">
        <div class="block image-left">
          <div class="block-image">
            <b-img
              :src="require('@/assets/images/employee-benefits/add_value.jpg')"
              alt="Add Value To Businesses and Earn Revenue"
              fluid
            />
          </div>
          <div class="block-content">
            <h3 class="h3 font-weight-bold mb-4 text-md-left text-center">
              Add Value To Businesses and Earn Revenue
            </h3>
            <p class="text-md-left text-center">
              In the form of an Employer-Sponsored Program or as a Voluntary
              Employee Sign-Up.
            </p>
          </div>
        </div>
        <div class="block image-right">
          <div class="block-image">
            <b-img
              :src="
                require('@/assets/images/employee-benefits/quick_easy_enrollment.jpg')
              "
              alt="Quick and Easy Enrollment"
              fluid
            />
          </div>
          <div class="block-content">
            <h3 class="h3 font-weight-bold  mb-4 text-md-left text-center">
              Quick and Easy Enrollment
            </h3>
            <p class="text-md-left text-center">
              Employee enrollment can be completed in 2 minutes.
            </p>
          </div>
        </div>
        <div class="block image-left">
          <div class="block-image">
            <b-img
              :src="
                require('@/assets/images/employee-benefits/serve_clients.jpg')
              "
              alt="Learn How to Best Serve Your Clients"
              fluid
            />
          </div>
          <div class="block-content">
            <h3 class="h3 font-weight-bold mb-4 text-md-left text-center">
              Learn How to Best Serve Your Clients
            </h3>
            <p class="text-md-left text-center">
              Obtain measurable data to reduce employee benefit inquiries and
              overall financial stress.
            </p>
          </div>
        </div>
      </div>
    </b-container>
    <b-container fluid class="bg-gray-100 mt-5 pt-4">
      <div class="sm-container">
        <h2 class="h2 mb-5 pt-5 font-weight-bolder text-center">
          Protect Your Client’s Financial Futures
        </h2>
        <b-row align-h="center" class="feature-grid pt-5 pb-4">
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center mw-300px"
          >
            <IconSB style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">ScoreBuilder<sup>&reg;</sup></p>
            <p class="mw-320px">
              Achieve your goal score with a personalized, 120-day action plan.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconSCR style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">ScoreBoost<sup>&trade;</sup></p>
            <p class="mw-320px">
              Know how much to pay and when with strategic payment plans.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconFI style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">$1MM Fraud Insurance</p>
            <p class="mw-320px">
              Protect not just yourself but your whole family from identity
              theft.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconMoneyManager
              style="height:60px;"
              class="icon icon-primary mb-4"
            />
            <p class="font-weight-semibold">Money Manager</p>
            <p class="mw-320px">
              Track all your credit cards, bank statements, and accounts in one
              place.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center mw-300px"
          >
            <IconPM style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">PrivacyMaster<sup>&reg;</sup></p>
            <p class="mw-320px">
              Stop data traffickers from sharing or selling your information.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconAlerts style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">Alerts</p>
            <p class="mw-320px">
              Receive alerts for suspicious activity and credit monitoring
              events.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconAB style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">Action Buttons</p>
            <p class="mw-320px">
              Report inaccuracies and ask questions directly with your
              creditors.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <Icon3BR style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">3B Report &amp; Scores</p>
            <p class="mw-320px">
              Track and compare your credit activity across all 3 credit
              bureaus.
            </p>
          </div>
        </b-row>
      </div>
    </b-container>
    <b-container class="my-5 py-4">
      <b-row>
        <b-col>
          <h2 class="h2 mb-5 font-weight-bolder text-center">
            Find Our Services On
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="d-flex flex-wrap justify-content-center align-items-center"
          >
            <img
              :src="require('@/assets/images/ease_logo.png')"
              alt="easy logo"
              class="d-block p-3"
            />
            <img
              :src="require('@/assets/images/i_solved_logo.png')"
              alt="ISOLVED logo"
              class="d-block p-3"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="pt-5 mt-5 bg-gray-100">
      <div class="sm-container text-center">
        <b-row>
          <b-col>
            <h2 class="h2 font-weight-bolder mb-4">
              Happy Employees For Life!
            </h2>
            <p class="mb-5">
              Contact us today to learn more about ScoreMaster® Employee
              Benefits.
            </p>
            <EmployeeBenefitsHubSpotForm />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container fluid class="pt-5 px-3 bg-gray-100">
      <b-row>
        <b-col cols="12">
          <p class="text-center">
            <small
              >Sources:
              <u
                ><a
                  target="_blank"
                  href="https://www.annuity.org/financial-literacy/disaster-preparedness/"
                  >Annuity</a
                ></u
              >
              and
              <u
                ><a
                  target="_blank"
                  href="https://www.benefitnews.com/news/wellness-programs-cut-sick-days-improves-productivity"
                  >EBN</a
                ></u
              ></small
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
    <ModalVideo
      channel="vimeo"
      videoId="693718297?h=7fe1e10a4f"
      :isOpen.sync="videoIsOpen"
    />
  </div>
</template>

<script>
import ModalVideo from '@/components/common/ModalVideo'
import PlayButton from '@/assets/svg/icon_play_button.svg'
import IconLightBulb from '@/assets/svg/icon_lightbulb.svg'
import IconCoins from '@/assets/svg/icon_coins.svg'
import IconShakingHands from '@/assets/svg/icon_shaking_hands.svg'
import IconSCR from '@/assets/svg/icon_SCR.svg'
import Icon3BR from '@/assets/svg/icon_3BR.svg'
import IconAB from '@/assets/svg/icon_AB.svg'
import IconAlerts from '@/assets/svg/icon_alerts.svg'
import IconMoneyManager from '@/assets/svg/icon_MM.svg'
import IconSB from '@/assets/svg/icon_SB.svg'
import IconFI from '@/assets/svg/icon_FI.svg'
import IconPM from '@/assets/svg/icon_PM.svg'
import EmployeeBenefitsHubSpotForm from '@/components/common/EmployeeBenefitsHubSpotForm'

export default {
  name: 'EmployeeBenefitsBrokers',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    ModalVideo,
    PlayButton,
    IconLightBulb,
    IconCoins,
    IconShakingHands,
    IconSCR,
    Icon3BR,
    IconAB,
    IconAlerts,
    IconMoneyManager,
    IconSB,
    IconFI,
    IconPM,
    EmployeeBenefitsHubSpotForm,
  },
  data() {
    return {
      videoIsOpen: false,
      schemaData: [
        {
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: 'ScoreMaster Employee Benefits',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '16795 Von Karman # 230',
            addressLocality: 'Irvine',
            addressRegion: 'California',
            postalCode: '92606',
            addressCountry: 'United States',
          },
          telephone: '+18772100180',
          openingHours:
            'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
          geo: {
            '@type': 'GeoCoordinates',
            name: 'ScoreMaster',
            postalCode: '92606',
            latitude: '33.6960883',
            longitude: '-117.8397577',
            description: 'You can find our office in this location.',
          },
          url: 'https://www.scoremaster.com/',
          logo:
            'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
          image:
            'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
          priceRange: '$$',
          aggregateRating: {
            ratingValue: '5',
            ratingCount: '65',
          },
        },
        {
          '@context': 'https://schema.org',
          '@type': 'Service',
          serviceType: 'Employee Benefits Services',
          '@id': 'https://www.scoremaster.com/employee-benefits/#service',
          name: 'Employee Benefits',
          additionalType: 'https://en.wikipedia.org/wiki/Employee_Benefits',
          sameAs: 'http:productontology.org/id//Employee_Benefits',
          brand: 'ScoreMaster Employee Benefits',
          description:
            'Give ScoreMaster® to your employees as a benefit so they can get better deals and save money.',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.scoremaster.com/employee-benefits/#image',
            url:
              'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
            caption: 'Employee Benefits Services',
          },
          potentialAction: 'Get ScoreMaster',
          provider: {
            '@id': 'https://www.google.com/maps?cid=11670868934081200217',
          },
          serviceOutput:
            'ScoreMaster for Companies: Reducing Employee Financial Stress',
          url: 'https://www.scoremaster.com/employee-benefits/',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          isPartOf: 'https://www.scoremaster.com/#website',
          '@id': 'https://www.scoremaster.com/employee-benefits/#faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'Will a poor credit score affect employment?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "When you hear things like “a bad credit score can prevent you from getting a job,” it's actually not true. That's because employers don't pull your actual credit scores like a lender might. An employer must inform the applicant that the credit check might be used as part of the employment decision.",
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a good credit score?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Some benefits of having a good credit score includes significant savings on interest rates, better terms and availability on loan products, access to best credit cards, insurance discounts, more housing options and security deposit waivers on utilities.',
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a credit score over 800?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "You'll qualify for lower interest rates and higher credit limits. With an 800-plus credit score, you are considered very likely to repay your debts, so lenders can offer you better deals.",
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    openVideo: function(e) {
      e.preventDefault()
      this.videoIsOpen = !this.videoIsOpen
    },
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
}
</script>
