<template>
  <div>
    <Loader v-if="loading" loadingText="Submitting" />
    <b-form
      @submit.prevent="executeRecaptcha"
      novalidate
      :class="{ loading: loading }"
    >
      <b-row>
        <b-col cols="12">
          <p v-if="submitError" class="form-group--error text-center mb-3">
            {{ submitError }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6">
          <b-form-group
            id="firstname-group"
            label="First Name"
            label-for="firstname"
            label-size="sm"
            label-class="required"
          >
            <b-form-input
              id="firstname"
              name="firstname"
              v-model.trim.lazy="$v.form.firstname.$model"
              maxlength="100"
              required
              :class="{
                'form-group--error': $v.form.firstname.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.firstname)
            }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            id="lastname-group"
            label="Last Name"
            label-for="lastname"
            label-size="sm"
            label-class="required"
          >
            <b-form-input
              id="lastname"
              name="lastname"
              v-model.trim.lazy="$v.form.lastname.$model"
              maxlength="100"
              required
              :class="{
                'form-group--error': $v.form.lastname.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.lastname)
            }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6">
          <b-form-group
            id="company-group"
            label="Company"
            label-for="company"
            label-size="sm"
            label-class="required"
          >
            <b-form-input
              id="company"
              name="company"
              v-model.trim.lazy="$v.form.company.$model"
              maxlength="100"
              :class="{
                'form-group--error': $v.form.company.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.company)
            }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            id="website-group"
            label="Company Website"
            label-for="website"
            label-size="sm"
          >
            <b-form-input
              id="website"
              name="website"
              type="url"
              v-model.trim.lazy="$v.form.website.$model"
              maxlength="100"
              :class="{
                'form-group--error': $v.form.website.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.website)
            }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6">
          <b-form-group
            id="email-group"
            label="Email"
            label-for="email"
            label-size="sm"
            label-class="required"
          >
            <b-form-input
              id="email"
              name="email"
              type="email"
              v-model.trim.lazy="$v.form.email.$model"
              maxlength="100"
              required
              :class="{
                'form-group--error': $v.form.email.$error,
              }"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.email)
            }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group
            id="phone-group"
            label="Phone"
            label-for="phone"
            label-size="sm"
            label-class="required"
          >
            <b-form-input
              id="phone"
              name="phone"
              type="tel"
              v-model.trim.lazy="$v.form.phone.$model"
              maxlength="100"
              required
              :class="{
                'form-group--error': $v.form.phone.$error,
              }"
              v-mask="'###-###-####'"
            ></b-form-input>
            <small class="mt-2 form-group--error">{{
              validationMsg($v.form.phone)
            }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <Recaptcha ref="recaptcha" @gVerify="submitForm" />
      <div class="text-center">
        <b-button
          :disabled="$v.form.$invalid || loading"
          pill
          size="lg"
          type="submit"
          variant="secondary"
          class="w-100 mt-4 mb-3"
        >
          Please Contact Me
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import { validationMessage } from 'vuelidate-messages'
import Recaptcha from './Recaptcha'

const formMessages = {
  required: () => 'This field is required',
  email: () => 'Please enter a valid email',
  maxLength: () => 'Please enter a valid email',
  phone: () => 'Please enter a valid phone',
}

function phone(val) {
  if (val) {
    var regex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/
    var digits = val.replace(/\D/g, '')
    return regex.test(digits)
  } else {
    return true
  }
}

export default {
  name: 'HubspotForm',
  props: {
    formIdentifier: String,
  },
  components: {
    Recaptcha,
  },
  computed: mapState('member', ['tracking']),
  data() {
    return {
      loading: false,
      gSiteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
      submitError: '',
      form: {
        firstname: '',
        lastname: '',
        company: '',
        website: '',
        email: '',
        phone: '',
        hs_lead_status: 'Open',
        type_of_partnership: 'TBD',
        product_type: 'ScoreMaster',
        sales_vertical:
          this.formIdentifier === 'employer' ? 'Employee Benefits' : 'Mortgage',
        opportunity_size: 'TBD',
        director_of_sales: 'Rosario Paniagua',
        partner_class: 'Tier 1',
        commission_type: 'Company Sourced',
      },
      portalId: '9409493', // Hubspot portal id
      formGuid:
        this.formIdentifier === 'employer'
          ? '73f0376f-a0e0-445e-a17b-543c07f61728' // employer form guid
          : '755f0acb-54a4-43ec-b7c0-b94dba468add', // lender form guid
    }
  },
  validations: {
    form: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      company: {
        required,
      },
      website: {},
      email: {
        required,
        email,
        maxLength: maxLength(254),
      },
      phone: {
        required,
        phone,
      },
    },
  },
  methods: {
    submitForm() {
      if (!this.$v.form.$invalid) {
        let postObj = {
          submittedAt: Date.now(),
          fields: [],
          context: {
            pageUri: `www.scoremaster.com${this.$route.path}`,
            pageName: this.$route.name,
          },
        }

        for (let field in this.form) {
          postObj.fields.push({
            name: field,
            value: this.form[field],
          })
        }

        axios
          .post(
            `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`,
            postObj,
            {
              headers: {
                'Content-type': 'application/json',
              },
            }
          )
          .then(() => {
            this.loading = false

            if (this.$gtm.enabled()) {
              if (this.formIdentifier === 'employer') {
                this.$gtm.trackView(
                  'EmployerSignupCompleted',
                  '/employer-signup/completed'
                )
                this.$gtm.trackEvent('employer_signup')
              } else {
                this.$gtm.trackView(
                  'LenderSignupCompleted',
                  '/lender-signup/completed'
                )
                this.$gtm.trackEvent('lender_signup')
              }
            }

            this.$router.push('/thank-you')
          })
          .catch(() => {
            this.loading = false
            this.submitError =
              'Sorry there was a problem with submitting your form. Please try again.'
          })
      }
    },

    // display error message
    validationMsg: validationMessage(formMessages),

    // execute the recaptcha widget
    executeRecaptcha() {
      this.loading = true
      this.$refs.recaptcha.execute()
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
}
.loader {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
