<template>
  <div>
    <b-container class="pt-4 mt-5">
      <b-row>
        <b-col cols="12">
          <h1 class="h2 font-weight-bolder mb-4 text-center">
            Contact Us
          </h1>
          <p class="text-center mb-5">
            Contact us today to learn more about ScoreMaster® Employee Benefits.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/josh-berman/meeting-with-employee-benefits-sales-account-representative?embed=true"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'EmployerSignup',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => {
    document.dispatchEvent(new Event('x-app-rendered'))

    //  hubspot calendar form script
    const hubspotScript = document.createElement('script')
    hubspotScript.setAttribute(
      'src',
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    )
    document.head.appendChild(hubspotScript)
  },
}
</script>
