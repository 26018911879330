<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/getstarted_hero.jpg')"
      :mobileImage="require('@/assets/images/getstarted_hero_mobile.jpg')"
      :mobileHeight="'405px'"
      :height="'705px'"
    >
      <b-container class="h-100">
        <b-row align-h="start" class="h-100">
          <b-col cols="12" lg="7" xl="5" offset-lg="1">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <h1 class="h1 font-weight-bolder mb-5 mx-md-5 mt-3">
                  Get Started with ScoreMaster<sup>&reg;</sup>
                </h1>
                <div
                  class="d-flex justify-content-between align-items-center bg-white rounded-10 box-shadow mx-md-5 px-3 py-4 my-4 position-relative"
                >
                  <p class="h3 font-weight-bold text-text-black m-0">
                    Consumers
                  </p>
                  <b-link
                    :to="{ name: 'IndividualSignup' }"
                    class="stretched-link"
                  >
                    <BtnArrow style="height:30px;" class="icon icon-orange" />
                  </b-link>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center bg-white rounded-10 box-shadow mx-md-5 px-3 py-4 my-4 position-relative"
                >
                  <p class="h3 font-weight-bold text-text-black m-0">
                    Lenders
                  </p>
                  <b-link
                    t
                    :to="{ name: 'LenderSignup' }"
                    class="stretched-link"
                  >
                    <BtnArrow style="height:30px;" class="icon icon-orange" />
                  </b-link>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center bg-white rounded-10 box-shadow mx-md-5 px-3 py-4 my-4 position-relative"
                >
                  <p class="h3 font-weight-bold text-text-black m-0">
                    Employers
                  </p>
                  <b-link
                    :to="{ name: 'EmployerSignup' }"
                    class="stretched-link"
                  >
                    <BtnArrow style="height:30px;" class="icon icon-orange" />
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
  </div>
</template>

<script>
import BtnArrow from '@/assets/svg/icon_btnArrow.svg'

export default {
  name: 'GetStarted',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    BtnArrow,
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .hero-content {
    margin-top: 200px;
    padding: 1.5rem 1.5rem !important;
  }
}
</style>
