<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/lender_benefit_hero.jpg')"
      :mobileImage="require('@/assets/images/lender_benefit_hero_mobile.jpg')"
    >
      <b-container class="h-100">
        <b-row align-h="start" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="h1 font-weight-bolder mb-4">
                  Close More Deals
                </p>
                <h1 class="h4">
                  Give ScoreMaster<sup>&reg;</sup> to your customers to close
                  more and better-quality deals.
                </h1>
                <SignupLinkButton
                  :to="{ name: 'LenderSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                  >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="mt-5 pt-5">
      <p class="h2 text-center font-weight-bolder mb-5 px-3 d-md-none">
        You &amp; Your Customers Win
      </p>
      <b-row align-h="center" class="mb-5">
        <b-col cols="12" md="7" lg="5" offset-lg="1" order-md="1">
          <LenderChart @switch="onChartSwitch"></LenderChart>
        </b-col>
        <b-col cols="12" md="5" class="px-0">
          <div class="block-content" v-if="showBeforeChart">
            <p class="h2 font-weight-bolder mb-4 d-none d-md-block">
              You &amp; Your <br class="d-none d-sm-block d-xl-none" />Customers
              Win
            </p>
            <p class="h5 font-weight-bold">
              Earn loyalty as they get more points for a better deal
            </p>
            <p>
              Give ScoreMaster<sup>&reg;</sup> to your prospects for free. After
              a one-minute enrollment, you can see how many more credit score
              points they can get within your loan process. It’s also the
              perfect opportunity to create customer loyalty.
            </p>
            <p>
              You can qualify more people, give larger loans and retain more
              revenue by giving a better deal from the start.*
            </p>
            <SignupLinkButton
              :to="{ name: 'LenderSignup' }"
              pill
              variant="secondary"
              class="w-100 mt-4"
              >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
            >
          </div>
          <div class="block-content" v-else>
            <p class="h2 font-weight-bolder mb-4 d-none d-md-block">
              You &amp; Your Customers Win
            </p>
            <p class="h5 font-weight-bold">
              How Your Customer is Changed Post Loan Closing
            </p>
            <p>
              Your customer’s credit score and DTI are more stable after the
              loan with ScoreMaster<sup>&reg;</sup>’s ongoing education,
              protection and awareness. This helps reduce default rates and
              limits refinancing because your customer got a better deal from
              the start.*
            </p>
            <p>
              A better performing loan with less risk can earn lenders more
              money when the loan is sold.
            </p>
            <SignupLinkButton
              :to="{ name: 'LenderSignup' }"
              pill
              variant="secondary"
              class="w-100 mt-4"
              >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="mt-5 pt-3 px-0">
      <div class="alternating-blocks fullwidth">
        <AlternatingBlock
          :image="require('@/assets/images/lender_benefit_sharing.jpg')"
          :mobileImage="
            require('@/assets/images/lender_benefit_sharing_mobile.jpg')
          "
          title="credit score monitoring for lenders"
          titleMobile="lenders credit score monitoring tool"
        >
          <template slot="title">
            Real-Time Sharing <br class="d-md-none" />with You
          </template>
          <p>
            The open collaboration feature is the future of lending, allowing
            customers to share their ScoreMaster<sup>&reg;</sup>
            Plan with you in real-time. Discuss the value of more credit score
            points and show them how spending may impact their loan process. Use
            this opportunity to walk them through loan options and better deals.
          </p>

          <p class="h5 font-weight-bold">
            Sharing is Easy &amp; Secure
          </p>
          <p>
            Your customers only need to enter your email address to grant you
            limited access to their ScoreMaster<sup>&reg;</sup> Plan.
          </p>
          <SignupLinkButton
            :to="{ name: 'LenderSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-4"
            >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
          >
        </AlternatingBlock>
        <AlternatingBlock
          :image="require('@/assets/images/lender_benefit_branding.jpg')"
          :mobileImage="
            require('@/assets/images/lender_benefit_branding_mobile.jpg')
          "
          alignment="right"
          title="credit score monitoring for realtors"
          titleMobile="realtors credit score monitoring tool"
        >
          <template slot="title">
            Get a Competitive Edge <br class="d-md-none" />with ScoreMaster<sup
              >&reg;</sup
            >
          </template>
          <p class="h5 font-weight-bold">
            Make ScoreMaster<sup>&reg;</sup> Part of Your Brand
          </p>
          <p>
            Enhance your brand value with a co-brand of ScoreMaster<sup
              >&reg;</sup
            >
            in as little as one day. Make it known that your customers get
            ScoreMaster<sup>&reg;</sup> for free when they contact you.
          </p>
          <p>
            You will see more leads per marketing spend, higher consumer
            interest and do more deals.*
          </p>
          <p class="h5 font-weight-bold">
            Make ScoreMaster<sup>&reg;</sup> Part of Your Sales Pitch
          </p>
          <p>
            "We've got ScoreMaster<sup>&reg;</sup>. Enroll for free and see how
            many more credit score points you can get. Then let us show you
            better rates and options."
          </p>
          <SignupLinkButton
            :to="{ name: 'LenderSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-4"
            >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
          >
        </AlternatingBlock>
      </div>
      <p id="disclaimer" class="text-left">
        <small>
          <small class="font-weight-semibold">
            * ScoreMaster<sup>&reg;</sup> is a patent-pending educational
            feature simulating credit utilization’s effect on credit scores via
            payments or spending. Your results may vary and are not guaranteed.
          </small>
        </small>
      </p>
    </b-container>
  </div>
</template>

<script>
import AlternatingBlock from '@/components/common/AlternatingBlock'
import LenderChart from '@/components/charts/LenderChart'

export default {
  name: 'LenderBenefits',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  data() {
    return {
      showBeforeChart: true,
      schemaData: [
        {
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: 'ScoreMaster Lender Benefits',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '16795 Von Karman # 230',
            addressLocality: 'Irvine',
            addressRegion: 'California',
            postalCode: '92606',
            addressCountry: 'United States',
          },
          telephone: '+18772100180',
          openingHours:
            'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
          geo: {
            '@type': 'GeoCoordinates',
            name: 'ScoreMaster',
            postalCode: '92606',
            latitude: '33.6960883',
            longitude: '-117.8397577',
            description: 'You can find our office in this location.',
          },
          url: 'https://www.scoremaster.com/',
          logo:
            'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
          image:
            'https://www.blissdrive.com/wp-content/uploads/7-step-seo-process.png',
          priceRange: '$$',
          aggregateRating: {
            ratingValue: '5',
            ratingCount: '65',
          },
        },
        {
          '@context': 'https://schema.org',
          '@type': ['Service'],
          serviceType: 'Lender Benefits Services',
          '@id': 'https://www.scoremaster.com/lender-benefits/#service',
          name: 'Lender Benefits',
          additionalType: 'https://en.wikipedia.org/wiki/Lender_Benefits',
          sameAs: 'http:productontology.org/id//Lender_Benefits',
          brand: 'ScoreMaster Lender Benefits',
          description:
            'Give ScoreMaster® to your customers to close more and better-quality deals.',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.scoremaster.com/lender-benefits/#image',
            url:
              'https://www.blissdrive.com/wp-content/uploads/7-step-seo-process.png',
            caption: 'Lender Benefits Services',
          },
          potentialAction: 'Get ScoreMaster',
          provider: {
            '@id': 'https://www.google.com/maps?cid=11670868934081200217',
          },
          serviceOutput:
            'ScoreMaster for Lenders & Brokers: More Qualified Clients, Lower Risk',
          url: 'https://www.scoremaster.com/lender-benefits/',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          isPartOf: 'https://www.scoremaster.com/#website',
          '@id': 'https://www.scoremaster.com/lender-benefits/#faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'What does a credit score provide to lenders?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Credit score tells the lenders about your creditworthiness, which means the likelihood of whether or not you are able to pay back a loan based on your credit history.',
              },
            },
            {
              '@type': 'Question',
              name: 'Is Credit Scoring expensive for the lender?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Overall the credit rates are lower. The automated credit processes, including the credit scoring, making the credit granting process more efficient and less costly for lenders. This will in turn pass the savings to their customers.',
              },
            },
            {
              '@type': 'Question',
              name: 'What does a high credit score tell a lender?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'It means that if you have gotten a loan, a credit card, or even auto insurance, then the rate you paid was directly related to your credit score. Higher the score, better you look to the lenders. ',
              },
            },
          ],
        },
      ],
    }
  },
  components: {
    AlternatingBlock,
    LenderChart,
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered')),
      this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
  methods: {
    onChartSwitch(val) {
      this.showBeforeChart = val
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .block-content {
    padding: 0;
    p {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
