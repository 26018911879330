const data = {
  beforeLoanData: {
    with: [
      {
        x: 0,
        y: 720,
      },
      {
        x: 1,
        y: 720,
      },
      {
        x: 3,
        y: 730,
      },
      {
        x: 6,
        y: 729,
      },
      {
        x: 10,
        y: 742,
      },
      {
        x: 15,
        y: 750,
      },
      {
        x: 19,
        y: 762,
      },
      {
        x: 23,
        y: 775,
      },
      {
        x: 24,
        y: 775,
      },
      {
        x: 25,
        y: 775,
      },
      {
        x: 26,
        y: 776,
      },
      {
        x: 27,
        y: 776,
      },
      {
        x: 28,
        y: 778,
      },
      {
        x: 29,
        y: 778,
      },
      {
        x: 30,
        y: 780,
      },
    ],
    without: generateBaseline(),
  },
  afterLoanData: {
    with: [
      {
        x: 0,
        y: 780,
      },
      {
        x: 1,
        y: 769,
      },
      {
        x: 2,
        y: 765,
      },
      {
        x: 3,
        y: 762,
      },
      {
        x: 4,
        y: 759,
      },
      {
        x: 5,
        y: 751,
      },
      {
        x: 6,
        y: 750,
      },
    ],
    without: [
      {
        x: 0,
        y: 720,
      },
      {
        x: 1,
        y: 700,
      },
      {
        x: 2,
        y: 693,
      },
      {
        x: 3,
        y: 680,
      },
      {
        x: 4,
        y: 670,
      },
      {
        x: 5,
        y: 656,
      },
      {
        x: 6,
        y: 650,
      },
    ],
  },
}

function generateBaseline() {
  let arr = []
  for (let x = 0; x < 31; ++x) {
    arr.push({ x: x, y: 720 })
  }
  return arr
}

module.exports = data
