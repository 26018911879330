<template>
  <div class="lendersignup">
    <FullWidthImageHero
      :image="require('@/assets/images/lender_signup_hero.jpg')"
      :mobileImage="require('@/assets/images/lender_signup_hero_mobile.jpg')"
      :mobileHeight="'800px'"
      :height="'800px'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" lg="7" offset-lg="1" class="bg-white hero-text">
            <p class="h1 font-weight-bolder mb-4">
              For Lenders
            </p>
            <h1 class="h4 font-weight-bold mb-3">
              Give ScoreMaster<sup>&reg;</sup> as your first step!
            </h1>
            <p class="mb-5">
              Quickly maximize your customers' credit score. Discounted and
              Sponsored programs available.
            </p>
            <HubspotForm formIdentifier="lender" />
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <p id="disclaimer" class="text-left">
      <small>
        <small class="font-weight-semibold">
          ScoreMaster<sup>&reg;</sup> is a patent-pending educational feature
          simulating credit utilization’s effect on credit scores via payments
          or spending. Your results may vary and are not guaranteed.
        </small>
      </small>
    </p>
  </div>
</template>

<script>
import HubspotForm from '@/components/forms/HubspotForm'

export default {
  name: 'LenderSignup',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    HubspotForm,
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
