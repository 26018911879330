<template>
  <div>
    <b-container class="mt-5 p-4">
      <b-row>
        <b-col>
          <h1 class="h1 font-weight-bolder text-center mb-5">
            Frequently Asked Questions
          </h1>
          <h2 class="h2 font-weight-bolder text-center mb-5 pt-5">
            For Employers
          </h2>
          <div class="sm-container">
            <Accordion class="mb-5" :accordionData="accordionDataEmployers" />
          </div>
          <h2 class="h2 font-weight-bolder text-center mb-5 pt-5">
            For Brokers
          </h2>
          <div class="sm-container">
            <Accordion class="mb-5" :accordionData="accordionDataBrokers" />
          </div>
          <h2 class="h2 font-weight-bolder text-center mb-5 pt-5">
            For Providers
          </h2>
          <div class="sm-container">
            <Accordion class="mb-5" :accordionData="accordionDataProviders" />
          </div>
          <h2 class="h2 font-weight-bolder text-center mb-5 pt-5">
            Need more help?
          </h2>
          <div class="d-flex justify-content-center">
            <SignupLinkButton
              :to="{ name: 'EmployerSignup' }"
              pill
              variant="secondary"
              class="employee-benefits-btn mr-4"
              >Contact Us</SignupLinkButton
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion'

export default {
  name: 'EmployeeBenefitFaq',
  components: {
    Accordion,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  data() {
    return {
      accordionDataEmployers: [
        {
          title:
            'How can we offer ScoreMaster<sup>&reg;</sup>. to our employees?',
          content:
            'We offer both Employer-Sponsored, or 100% Employee Paid benefits. We are incredibly flexible to meet your individual business needs and have the ability to direct-bill employees. This means minimal administrative effort on your part.',
        },
        {
          title:
            'How are you different than some of the competing credit or identity theft products on the market?',
          content:
            'Unlike competing credit and identity theft products on the market today, we designed our product for B2B success allowing for an easily-integrated, customizable, all-in-one financial wellness solution that addresses an individual’s credit health, money management, and identity protection based on their personal needs.',
        },
        {
          title: 'How easy is it to sign up?',
          content:
            'Enrollment is as easy as clicking a link and signing up for an app.',
        },
        {
          title: 'Why is financial wellness important?',
          content:
            'Many employers may argue that their employees’ debts are not of concern, but there is a clear connection between employee financial stress and their work. When people are struggling to pay off their debts, it affects their mental state and ability to focus on the tasks at hand. This can have a severe impact on workplace productivity.',
        },
        {
          title: 'How can I create incentives for financial wellness?',
          content:
            'By implementing a financial wellness solution into your employee benefits package, you are showing your employees you care about their financial wellbeing. Opening up the conversation between you and your employees will help create a supportive working environment where they will feel empowered to make better financial decisions.',
        },
      ],
      accordionDataBrokers: [
        {
          title: 'Which HRIS platforms are you currently available on?',
          content:
            'We are currently on iSolved and Ease for simplified administration.',
        },
        {
          title: 'Do you offer commissions?',
          content:
            'Yes, ask your sales representative for more details during the initial quoting process for your clients.',
        },
      ],
      accordionDataProviders: [
        {
          title:
            'As a service provider, we already have an app, financial wellness tool, or platform, can we still work together?',
          content:
            'ConsumerDirect can provide you with a solution that best fits your needs, whether that be a full version of our ScoreMaster product or an A-la-carte feature that we embed to enhance your technology.',
        },
        {
          title: 'Do you have APIs?',
          content:
            'Yes. Our API calls offer customization for more complex types of integration. For example, we can build and host an enrollment funnel on your website with more customization capabilities than the standard signup widget.',
        },
        {
          title: 'Do you offer widgets?',
          content:
            'Yes. Our plug-and-play widget options offer the easiest implementation strategy to enable features directly within your platform.',
        },
        {
          title: 'Do you offer PWS functions?',
          content:
            'Yes. PWS offers unique administrative functions over customer accounts so you can make instant updates. For example, you can have the ability to see and select customer plan information, order a full Smart Credit 1B report, see a list of security questions for password recovery, and more.',
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
