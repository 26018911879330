<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card
        v-for="(data, index) in accordionData"
        :key="index"
        no-body
        class="mb-1"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div
            class="accordion__close font-weight-bold d-flex align-items-center justify-content-between"
            v-b-toggle="'accordion-item-' + index"
          >
            <span v-html="data.title" />
          </div>
        </b-card-header>
        <b-collapse
          :id="'accordion-item-' + index"
          accordion="my-accordion"
          role="tabpanel"
          appear="on"
          visible
        >
          <b-card-body>
            <b-card-text v-html="data.content" />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accodion',
  props: {
    accordionData: Array,
  },
}
</script>

<style lang="scss" scoped>
.accordion {
  border-bottom: 1px solid #ccc;

  &__close {
    position: relative;
    font-size: 18px;
    width: 100%;
    height: 60px;
    padding-right: 40px;

    @media (max-width: 960px) {
      font-size: 16px;
    }

    &::after {
      content: '';
      right: 10px;
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.not-collapsed::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .card {
    background-color: transparent;
    border: none;
  }

  .card-header {
    border: 1px solid #ccc;
    border-left: none;
    border-right: none;
    background-color: transparent;
  }

  .card-body {
    padding: 20px 0;
  }
}
</style>
